.formArea {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.contactText {
  border: 1px solid #ddd;
  border-radius: 6px;
  margin-left: 40px;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  max-width: 1080px;
  background: #eaedf2;
  font-size: 30px;
  margin-bottom: 8px;
}

.labelForm {
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
}

.pageText {
  font-weight: bold;
  font-size: 20px;
}

.requiredInformation {
  color: red;
}

.submitButton {
  margin-top: 30px;
  font-family: inherit;
  font-size: 20px;
  background: royalblue;
  color: white;
  padding: 0.7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}

.submitButton span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.submitButton svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.submitButton:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.submitButton:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.submitButton:hover span {
  transform: translateX(5em);
}

.submitButton:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .mainContactArea {
    margin-top: 0px;
  }

  .pageTitle {
    font-size: 20px;
  }

  .pageText {
    font-size: 20px;
  }

  .contactText {
    width: 80%;
    margin-left: 0;
    font-size: 28px;
  }

  .labelForm,
  .submitButton {
    margin: 0;
  }
}

@media (max-width: 480px) {
  .mainContactArea {
    margin-top: 0px;
  }

  .pageTitle {
    font-size: 20px;
  }

  .pageText {
    font-size: 10px;
  }

  .contactText {
    width: 280px;
    margin-left: 0;
    font-size: 15px;
  }

  .labelForm,
  .submitButton {
    margin: 0;
    font-size: 12px;
  }
}
