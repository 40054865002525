.topAboutMain {
  display: flex;
}

.topAbout-icon {
  width: 274px;
  height: 262px;
  margin-left: 50px;
  margin-right: 85px;
  border-radius: 50%;
}

.topAbout-txt {
  max-width: 560px;
  padding: 35px;
  background-color: rgb(215, 238, 255);
  font-size: 20px;
  text-align: left;
  white-space: pre-line;
}

.topAbout-viewMore {
  text-align: right;
}

.navItem {
  position: relative;
}

.navItem:hover {
  color: black;
}

.navItem:before {
  visibility: hidden;
  position: absolute;
  transform: scaleX(0);
  bottom: 0;
  width: 100%;
  height: 2px;
  margin: -5px 0;
  background-color: black;
  content: '';
  transition: all 0.4s ease-in-out 0s;
}

.navItem:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.rightIcon {
  font-size: 20px;
}
