html {
  height: 100%;
}

.App {
  min-height: 100vh;
  position: relative;
  padding-bottom: 100px;
  box-sizing: border-box;
  text-align: center;
}

a {
  color: black;
  text-decoration: none;
}

li {
  list-style: none;
}

p {
  margin: 0px;
}

.title {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 0.4rem;
  padding-top: 100px;
}

body {
  font-family: 'Helvetica Neue', Arial, 'Hiragino Kaku Gothic ProN',
    'Hiragino Sans', Meiryo, sans-serif;
  line-height: 1.9;
  margin: 0;
  padding: 0;
  width: 100%;
}

.area {
  padding: 0 1.5rem;
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 45px;
  margin-bottom: 70px;
}

.nav-item {
  position: relative;
}

.nav-item:hover {
  color: white;
}

.nav-item:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  margin: -5px 0;
  background-color: black;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.4s ease-in-out 0s;
}

.nav-item:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

#topAbout,
#topSkill,
#topProduct,
#top {
  text-align: center;
  margin-bottom: 16px;
}
