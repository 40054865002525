.iconImg {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.topSkillMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skillItem {
  margin: 30px;
}

.skillName {
  font-size: 20px;
}
