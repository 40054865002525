.topMain {
  width: 700px;
  margin-top: 50px;
}

.topMainArea {
  display: flex;
}

.topMain-text {
  font-size: 65px;
  margin: 180px 0px;
  letter-spacing: 0.15rem;
}
