.headerLogo {
  display: flex;
  margin: 0 16px;
}

.AboutMain {
  display: flex;
  justify-content: space-between;
}

.paragraph {
  padding: 25px 0;
}

.backHome {
  font-size: 50px;
  margin: 40px 0px;
  line-height: 1.75rem;
}

.backHome :hover {
  color: #60a5fa;
  font-family: Kaushan;
  text-decoration: inherit;
}

.about-icon {
  width: 470px;
  height: auto;
  margin-right: 32px;
}

.careerTitle {
  font-size: 16px;
}

h1 {
  font-size: 40px;
}

.scrollButton {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 3px 5px 4px grey;
  font-size: 50px;
  cursor: pointer;
  border: none;
  z-index: 100;
}

.topButton {
  padding: 8px;
  vertical-align: middle;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .AboutMain {
    display: block;
  }

  .about-icon {
    width: 60%;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .AboutMain {
    display: block;
  }
  .about-icon {
    width: 300px;
    margin: 0;
  }
  .scrollButton {
    right: 2px;
    bottom: 25px;
    width: 60px;
    height: 60px;
    margin-right: 10px;
    padding: 0;
    font-size: 30px;
    z-index: 200;
  }
}
