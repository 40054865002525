@media screen and (min-width: 768px) and (max-width: 1024px) {
  .navBox {
    justify-content: center;
  }

  .title,
  .area {
    padding: 0;
  }

  .topMainArea,
  .topAboutMain {
    display: block;
  }
  .topMain {
    width: 80%;
    margin-top: 0;
  }

  .topMain-text {
    margin: 80px 0px;
    font-size: 55px;
  }

  .topAbout-txt {
    width: 90%;
    margin: auto;
  }

  .navItem {
    margin: 0;
    margin-top: 25px;
    padding: 2px 20px;
    border-radius: 12px;
    background-color: #111;
    color: #fff;
    font-size: 1.2rem;
  }

  .navItem:hover {
    color: #fff;
  }

  .navItem:before {
    margin: -5px -20px;
  }

  .ProductImg {
    margin: 5px;
  }

  footer {
    min-width: 100vw;
  }

  .footerArea {
    min-width: 100vw;
  }
}

/* スマホ対応 */
@media (max-width: 767px) {
  li {
    margin: 0;
  }

  .header {
    padding-top: 10px;
  }

  .title,
  .area {
    padding: 0;
  }

  .navBox {
    display: none;
  }

  .topMainArea,
  .topAboutMain,
  .topSkillMain {
    display: block;
  }
  .topMain {
    width: 300px;
  }
  .topMain-text {
    margin: 0;
    font-size: 38px;
    text-align: center;
  }

  .topAbout-txt {
    width: 70%;
    margin: auto;
  }

  .topAbout-icon {
    width: 200px;
    height: 200px;
    margin-left: 72px;
  }

  .navItem {
    margin: 0;
    margin-top: 25px;
    padding: 2px 20px;
    border-radius: 12px;
    background-color: #111;
    color: #fff;
    font-size: 1.2rem;
  }

  .navItem:hover {
    color: #fff;
  }

  .navItem:before {
    margin: -5px -20px;
  }

  .iconImg {
    width: 160px;
    height: 160px;
  }

  .ProductImg {
    width: 220px;
  }

  .contact-txt {
    font-size: 15px;
  }

  footer {
    width: 100vw;
  }
  .EmptyBox {
    display: none;
  }
}
