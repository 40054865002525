header {
  z-index: 200;
  position: fixed;
  top: 0px;
  left: 0px;
}

.navBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  max-width: 1080px;
  height: 80px;
  margin: 0 auto;
  padding: 0 4%;
  backdrop-filter: blur(5px);
}

.menuList {
  position: fixed;
}

.gNav {
  position: fixed;
  width: 100%;
  top: 0;
}

ul {
  display: flex;
  width: 100%;
  margin-left: 50%;
  font-size: 20px;
  cursor: pointer;
}

li {
  list-style: none;
  margin-left: 40px;
}

.scroll:hover {
  color: #1997f1;
}

.scroll {
  display: inline-block;
  position: relative;
  z-index: 99;
  margin: 0 1.3rem;
  line-height: 50px;
  letter-spacing: 0.12rem;
  cursor: pointer;
  font-family: 'cursive';
  font-size: 20px;
}
