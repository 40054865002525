footer {
  width: 100%;
  padding: 40px 0;
  background-color: #d9efff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  position: absolute;
  bottom: 0;
}

.githubIcon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
}
