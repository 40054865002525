.topProductArea {
  justify-content: center;
}

.topProductMain {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.topProductList {
  margin: 20px;
  flex: 0 1 calc(33.33% - 10px);
}

.ProductImg {
  position: relative;
  width: 340px;
  cursor: pointer;
  transition: 0.3s;
  z-index: 1;
}

.ProductName {
  font-size: 16px;
}

.ProductImg:hover {
  opacity: 0.8;
}

.EmptyBox {
  width: 349px;
  height: 361px;
  margin: 20px;
}
